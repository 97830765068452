import React from 'react';
import { Navigate, useRoutes } from "react-router-dom";

// layouts
const DashboardLayout = React.lazy(() => import("./layouts/dashboard"));
const SimpleLayout = React.lazy(() => import("./layouts/simple"));

// pages
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const Page404 = React.lazy(() => import("./pages/Page404"));
const DashboardAppPage = React.lazy(() => import("./pages/DashboardAppPage"));
const DailyUpdates = React.lazy(() => import("./pages/Employee/daily-updates"));
const Reports = React.lazy(() => import("./pages/Employee/reports"));
const Role = React.lazy(() => import("./pages/Role/role"));
const TeamMember = React.lazy(() => import("./pages/TeamMembers/teammembers"));
const Agent = React.lazy(() => import("./pages/Agent/agent"));
const MIS_BulkUploader = React.lazy(() => import("./pages/Admin/MIS_BulkUploader"));
const MISComponent = React.lazy(() => import("./pages/MIS/mis"));
const MISReport = React.lazy(() => import("./pages/MIS/misReport"));
const MISSummery = React.lazy(() => import("./pages/MIS/missummery"));
const RcDetails = React.lazy(() => import("./pages/RC/rcDetails"));

// ----------------------------------------------------------------------
export default function Router({ isAuthenticated, onLogin, onLogout }) {
  console.log("isAuthenticated:", isAuthenticated);

  const routes = useRoutes([
    {
      path: "/admin",
      element:
        isAuthenticated === "Super-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "role", element: <Role /> },
        { path: "daily-updates", element: <DailyUpdates /> },
        { path: "report", element: <Reports /> },
        { path: "employees", element: <TeamMember /> },
        { path: "agent", element: <Agent /> },
        { path: "mis", element: <MISComponent /> },
        { path: "mis-bulk-uploader", element: <MIS_BulkUploader /> },
        { path: "mis-report", element: <MISReport /> },
        { path: "mis-summery", element: <MISSummery /> },
        { path: "rc-details", element: <RcDetails /> },
      ],
    },
    {
      path: "/employee",
      element:
        isAuthenticated === "Branch-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "daily-updates", element: <DailyUpdates /> },
        { path: "reports", element: <Reports /> },
        { path: "employees", element: <TeamMember /> },
        { path: "agent", element: <Agent /> },
        { path: "mis", element: <MISComponent /> },
        { path: "mis-report", element: <MISReport /> },
      ],
    },
    {
      path: "/rc-admin",
      element:
        isAuthenticated === "RC-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [{ path: "rc-details", element: <RcDetails /> }],
    },
    {
      path: "/login",
      element:
        isAuthenticated === "Super-Admin" ? (
          <Navigate to="/admin/dashboard" />
        ) : isAuthenticated === "Branch-Admin" ? (
          <Navigate to="/employee/daily-updates" />
        ) : isAuthenticated === "RC-Admin" ? (
          <Navigate to="/rc-admin/rc-details" />
        ) : (
          <LoginPage onLogin={onLogin} />
        ),
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: (
            <Navigate
              to={
                isAuthenticated === "Super-Admin"
                  ? "/admin/dashboard"
                  : isAuthenticated === "Branch-Admin"
                  ? "/employee/daily-updates"
                  : "/login"
              }
            />
          ),
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
